export const environment = {
    production: true,
    baseUrl: 'https://api.sm-1322.sandbox.spartacus-mma.com/api/v1',
    chatIframeUrl: 'https://chat.sm-1322.sandbox.spartacus-mma.com/chat',
    videoMetricsUrl: 'https://video.spartacus-mma.com/api/v1',
    chatUrl: 'https://chat.sm-1322.sandbox.spartacus-mma.com',
    videoStreamingApiAuthKey: 'cq2xiw5Qfqjgocm684kb93D3mwjAjnpAnvrdnSgavV2EBwdPVcvnew4sZvVYUhK4',
    kicksUrl: 'https://kicks.spartacus-mma.com/api',
    bloodsUrl: 'https://bloods.spartacus-mma.com/api',
    videosUrl: 'https://videos.spartacus-mma.com/api',
};
